import axios from "axios";
import store from "./store";

const instance = axios.create({
  //baseURL: "https://webapi.dinbingo.no",
  //baseURL: 'http://localhost:4000'
  baseURL: "https://webapi.bingosystem.no",
});

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.get["Accepts"] = "application/json";

export default instance;
